import React, { useState } from 'react';
import '../static/MainPage.css'; // Correct relative path
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import { useNavigate } from "react-router-dom";
import config from '../config';
import Header from './HeaderUser';
import Footer from './FooterUser';
import LoadingSpinner from './LoadingSpinner';

function PropertyInsuranceRequest() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [policyData, setPolicyData] = useState({
        ownerfirstname: '',
        ownerlastname: '',
        insuredfirstname: '',
        insuredlastname: '',
        year: '',
        size: 0,
        storey: 0,
        numofwc: 0,
        type: '',
        sum: 0,
        additionals: [],
        description: '',
        insurance: 'no',
        address: ''
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setPolicyData({
            ...policyData,
            [name]: name === 'year' ? parseInt(inputValue) : inputValue,
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setPolicyData(prevState => {
            const updatedAdditionals = checked
                ? [...prevState.additionals, name]
                : prevState.additionals.filter(item => item !== name);

            return {
                ...prevState,
                additionals: updatedAdditionals,
            };
        });
    };

    const jwtToken = localStorage.getItem("token");

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const requiredFields = ['ownerfirstname', 'ownerlastname', 'year', 'size', 'storey', 'numofwc', 'insuredfirstname', 'insuredlastname'];
        for (const field of requiredFields) {
            if (policyData[field] === '' || policyData[field] === null || policyData[field] === undefined) {
                alert('Sva polja su obavezna!');
                setLoading(false);
                return;
            }
        }

        fetch(`${config.BASE_URL}api/policyreq/property`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(policyData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setLoading(false);
                localStorage.setItem('type','IMOVINSKO OSIGURANJE');
                localStorage.setItem('id', data.id);
                navigate('/main-page/confirmation');
            })
            .catch((error) => {
                setLoading(false);
                alert("Došlo je do greške, molimo pokušajte ponovo!");
                console.error('Error:', error);
            });
    };

    return (
        <div>
            <Header />
            <section>
                <h1 className="heading">Unesite podatke o Vašoj nekretnini</h1>
            </section>
            <div className="columns w-row kasko-form-container">
                <div className="w-col w-col-6">
                    <div className="w-form">
                        <form id="email-form" name="email-form" onSubmit={handleSubmit} data-name="Email Form" data-wf-page-id="655606539083e607ba6bc88b" data-wf-element-id="d107ecf6-3a82-f695-7248-e25f860511bc">
                            <label htmlFor="field-2">Ime vlasnika</label>
                            <input type="text" className="text-field-2 w-input" maxLength={256} name="ownerfirstname" data-name="Name" placeholder id="firstname" value={policyData.ownerfirstname}
                                   onChange={handleInputChange} />
                            <label htmlFor="field-3">Prezime vlasnika</label>
                            <input type="text" className="text-field-3 w-input" maxLength={256} name="ownerlastname" data-name="Name 10" placeholder id="lastname" value={policyData.ownerlastname}
                                   onChange={handleInputChange} />
                            <label htmlFor="field-2">Ime ugovarača osiguranja</label>
                            <input type="text" className="text-field-2 w-input" maxLength={256} name="insuredfirstname" data-name="Name" placeholder id="firstname" value={policyData.insuredfirstname}
                                   onChange={handleInputChange} />
                            <label htmlFor="field-3">Prezime ugovarača osiguranja</label>
                            <input type="text" className="text-field-3 w-input" maxLength={256} name="insuredlastname" data-name="Name 10" placeholder id="lastname" value={policyData.insuredlastname}
                                   onChange={handleInputChange} />
                            <label htmlFor="field-3">Adresa nekretnine</label>
                            <input type="text" className="text-field-3 w-input" maxLength={256} name="address" data-name="Name 10" placeholder id="address" value={policyData.address}
                                   onChange={handleInputChange} />
                            <label htmlFor="field-4">Godina gradnje</label>
                            <select id="field-4" name="year" data-name="Field 4" required className="select-field-3 w-select" value={policyData.year}
                                    onChange={handleInputChange}>
                                <option value="">Izaberite godinu...</option>
                                <option value={2024}>2024</option>
                                <option value={2023}>2023</option>
                                <option value={2022}>2022</option>
                                <option value={2021}>2021</option>
                                <option value={2020}>2020</option>
                                <option value={2019}>2019</option>
                                <option value={2018}>2018</option>
                                <option value={2017}>2017</option>
                                <option value={2016}>2016</option>
                                <option value={2015}>2015</option>
                                <option value={2014}>2014</option>
                                <option value={2013}>2013</option>
                                <option value={2012}>2012</option>
                                <option value={2011}>2011</option>
                                <option value={2010}>2010</option>
                                <option value={2009}>2009</option>
                                <option value={2008}>2008</option>
                                <option value={2007}>2007</option>
                                <option value={2006}>2006</option>
                                <option value={2005}>2005</option>
                                <option value={2004}>2004</option>
                                <option value={2003}>2003</option>
                                <option value={2002}>2002</option>
                                <option value={2001}>2001</option>
                                <option value={2000}>2000</option>
                                <option value={1999}>1999</option>
                                <option value={1998}>1998</option>
                                <option value={1997}>1997</option>
                                <option value={1996}>1996</option>
                                <option value={1995}>1995</option>
                                <option value={1994}>1994</option>
                                <option value={1993}>1993</option>
                                <option value={1992}>1992</option>
                                <option value={1991}>1991</option>
                                <option value={1990}>1990</option>
                                <option value={1989}>1989</option>
                                <option value={1988}>1988</option>
                                <option value={1987}>1987</option>
                                <option value={1986}>1986</option>
                                <option value={1985}>1985</option>
                                <option value={1984}>1984</option>
                                <option value={1983}>1983</option>
                                <option value={1982}>1982</option>
                                <option value={1981}>1981</option>
                                <option value={1980}>1980</option>
                                <option value={1979}>1979</option>
                                <option value={1978}>1978</option>
                                <option value={1977}>1977</option>
                                <option value={1976}>1976</option>
                                <option value={1975}>1975</option>
                                <option value={1974}>1974</option>
                                <option value={1973}>1973</option>
                                <option value={1972}>1972</option>
                                <option value={1971}>1971</option>
                                <option value={1970}>1970</option>
                                <option value={1969}>1969</option>
                                <option value={1968}>1968</option>
                                <option value={1967}>1967</option>
                                <option value={1966}>1966</option>
                                <option value={1965}>1965</option>
                                <option value={1964}>1964</option>
                                <option value={1963}>1963</option>
                                <option value={1962}>1962</option>
                                <option value={1961}>1961</option>
                                <option value={1960}>1960</option>
                                <option value={1959}>1959</option>
                                <option value={1958}>1958</option>
                                <option value={1957}>1957</option>
                                <option value={1956}>1956</option>
                                <option value={1955}>1955</option>
                                <option value={1954}>1954</option>
                                <option value={1953}>1953</option>
                                <option value={1952}>1952</option>
                                <option value={1951}>1951</option>
                                <option value={1950}>1950</option>
                                <option value={1949}>Starije od 1950</option>
                            </select>
                            <label htmlFor="name">Veličina objekta (u kvadratnim metrima)</label>
                            <input type="number" className="text-field-4 w-input" maxLength={256} name="size" data-name="Name 5" placeholder id="name-5" value={policyData.size}
                                   onChange={handleInputChange} min="0" />
                            <label htmlFor="field">Spratnost objekta</label>
                            <input type="number" className="text-field-4 w-input" maxLength={256} name="storey" data-name="Name 5" placeholder id="name-5" value={policyData.storey}
                                   onChange={handleInputChange} min="0" />
                            <label htmlFor="field">Broj mokrih čvorova</label>
                            <input type="number" className="text-field-4 w-input" maxLength={256} name="numofwc" data-name="Name 5" placeholder id="name-5" value={policyData.numofwc}
                                   onChange={handleInputChange} min="0" />
                            <label htmlFor="field">Procijenjena građevinska vrijednost objekta po kvadratnom metru u KM (Napomena: vrijednost ne može biti manja od 600 KM/m2)</label>
                            <input type="number" className="text-field-4 w-input" maxLength={256} name="sum" data-name="Name 5" placeholder id="name-5" value={policyData.sum}
                                   onChange={handleInputChange} min="0" />
                            <label htmlFor="field">Tip objekta (kuća, stan, poslovni prostor...)</label>
                            <input type="text" className="text-field-4 w-input" maxLength={256} name="type" data-name="Name 5" placeholder id="name-5" value={policyData.type}
                                   onChange={handleInputChange} />
                            <label htmlFor="field">Opis (opciono)</label>
                            <input type="text" className="text-field-4 w-input" maxLength={256} name="description" data-name="Name 5" placeholder id="name-5" value={policyData.description}
                                   onChange={handleInputChange} />

                            <label htmlFor="insurance">Osiguranje stvari u nekretnini:</label>
                            <select id="insurance" name="insurance" className="select-field-3 w-select" value={policyData.insurance} onChange={handleInputChange}>
                                <option value="no">Ne</option>
                                <option value="yes">Da</option>
                            </select>

                            <fieldset>
                                <legend>Izaberite rizike:</legend>
                                <label>
                                    <input type="checkbox" name="poplava" checked={policyData.additionals.includes('poplava')} onChange={handleCheckboxChange} />
                                    Poplava
                                </label>
                                <label>
                                    <input type="checkbox" name="pozar" checked={policyData.additionals.includes('pozar')} onChange={handleCheckboxChange} />
                                    Požar
                                </label>
                                <label>
                                    <input type="checkbox" name="lom_stakla" checked={policyData.additionals.includes('lom_stakla')} onChange={handleCheckboxChange} />
                                    Lom stakla
                                </label>
                                <label>
                                    <input type="checkbox" name="elementarna_nepogoda" checked={policyData.additionals.includes('elementarna_nepogoda')} onChange={handleCheckboxChange} />
                                    Elementarna nepogoda
                                </label>
                                <label>
                                    <input type="checkbox" name="izliv_vode" checked={policyData.additionals.includes('izliv_vode')} onChange={handleCheckboxChange} />
                                    Izliv vode u stan/kuću
                                </label>
                            </fieldset>

                            <div className="w-layout-blockcontainer w-container kasko-form-footer">
                                <button type="submit" className="button-3 w-button">Kreiraj zahtjev za polisu</button>
                            </div>
                        </form>
                        <LoadingSpinner loading={loading} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PropertyInsuranceRequest;
